<template>
    <div class="bg re_sizing"  :class="{ 'padding-top': isiOS }">
        <div class="title re_relative re_flex_center re_font_18">
            <van-icon @click="goBack(1)" class="icon" name="arrow-left" size="22" />
            <span>提现规则</span>
        </div>
        <div class="content" v-html="ruleInfo.withdrawalRules"> </div>
    </div>
</template>

<script setup>
import { getDrawConfig } from './api.js'
import { ref, onMounted } from 'vue';
const ruleInfo = ref({});
const goBack = () => {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
        try {
            android.finish()
        } catch (error) {
            appJsImp.finish()
        }
    }
    if (isiOS) {
        window.webkit.messageHandlers.AppModel.postMessage('finish')
    }
}
const isiOS = ref(false); //ios终端
onMounted(() => {
    let u = navigator.userAgent;
    if(!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)){
        isiOS.value = true;
    }
    getDrawConfig().then(res => {
        if (res.code == 1) {
            ruleInfo.value = res.data
        }
    }).catch(err => {
        console.log(err)
    })
})
</script>

<style lang="less" scoped>
.bg {
    padding-top: 20px;
    text-align: center;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    &.padding-top {
        padding-top: 40px;
    }
    .title {
        width: 100%;
        font-weight: 600;
        .icon {
            position: absolute;
            left: 18px;
            top: 2px;
        }
    }
    .content{
        padding: 0 24px;
        color: #333;
        text-align: left;
        background: #fff;
    }
}
</style>